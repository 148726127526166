import React from 'react';


//componnents 
import Affiliate from '../components/Affiliate/Affiliate';
import Layout from '../components/layout';
import Seo from '../components/seo'


export default function pages ({location}){

    return(
        <Layout location={location}  background={false}>
            <Seo title="Affiliate Signup" description = 'Join the bender affiliate team today!'/>
                
                <div style = {{height:'100%',width:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', paddingTop:'10%', backgroundColor:'#ffffff' }}>
                    <Affiliate />
                    <iframe title = 'social-snowball-iframe' id="affiliate-sign-up-form" style={{border: 0, width: '100%', height:'100vh', }} src="https://socialsnowball.io/register-form/6851"></iframe>
                </div>
        </Layout>
        

    )

}