import React from 'react';
import './Affiliate.css'
import arrow from '../../images/arrow-right.png'
import discountCode from '../../images/discount-code.png'
import getPaid from '../../images/get-paid.png'
import MediaQuery from 'react-responsive'
import shareWithFriends from '../../images/share-with-friends.png'
import { StaticImage } from 'gatsby-plugin-image';

export default function Affiliate (){
    
    return(
        <div className = 'affiliate-main'>
            <div className = 'affiliate-title'>Bender Affiliate Program</div>
            <div className = 'affiliate-steps'>

                <div className = 'affiliate-step'>
                    <div className='a-step-text'>
                        Generate a personal discount code
                    </div>
                    
                        <img src = {discountCode}  width ={100} height ={100} objectFit = 'scale-down'/>
                    
                </div>

          
                <MediaQuery maxWidth= {599}>
                    <img src = {arrow}className = 'affiliate-arrow' width ={60} height ={60} objectFit = 'scale-down' style = {{transform:'rotate(90deg)'}}/>
                </MediaQuery>
                <MediaQuery minWidth= {600}>
                    <img src = {arrow}className = 'affiliate-arrow' width ={60} height ={60} objectFit = 'scale-down'/>
                </MediaQuery>
    

                <div className = 'affiliate-step'>
                    <div className='a-step-text'>
                        Share the code with your friends
                    </div>
                    
                        <img  src = {shareWithFriends} width ={100} height ={100} objectFit = 'scale-down'/>
                    
                </div>

                <MediaQuery maxWidth= {599}>
                    <img src = {arrow}className = 'affiliate-arrow' width ={60} height ={60} objectFit = 'scale-down' style = {{transform:'rotate(90deg)'}}/>
                </MediaQuery>
                <MediaQuery minWidth= {600}>
                    <img src = {arrow}className = 'affiliate-arrow' width ={60} height ={60} objectFit = 'scale-down' />
                </MediaQuery>
            
            

                <div className = 'affiliate-step'>
                    <div className='a-step-text'>
                        Get paid <strong>$5</strong> every time your code gets used 
                    </div>
                    
                        <img src = {getPaid} width ={100} height ={100} objectFit = 'scale-down'/>
                
                </div>

                
                
            </div>
        </div>

    )

}